<mat-sidenav-container>
	<mat-sidenav #sidenav mode="over">
		<app-sidebar></app-sidebar>
	</mat-sidenav>

	<mat-sidenav-content>
		<app-header></app-header>

		<section>
			<app-background></app-background>
			<router-outlet></router-outlet>
		</section>
	</mat-sidenav-content>
</mat-sidenav-container>
